// @ts-check

import React, { useState } from "react";
import { Alert, Button, Checkbox, Select } from "antd";
import { isArray } from "radash";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";

/**
 * @typedef {Object} Props
 *
 * @prop {import("../domain").ImportField} relationshipField
 * @prop {import("../domain").RelationshipMatchConfig} relationshipMatchConfig
 * @prop {import("../import-state/import-state").ImportStateService} service
 * @prop {import("./reduce-relationship-match-report").RelationshipMatchReport} relationshipMatchReport
 * @prop {{label: string; value: string;}[]} dataIndexOptions
 */

// TODO: Probably need to support not updating anything? 
// For example, what if there are no records in the system 
// to update? Then it is just an update.

/**
 * @param {Props} props
 */
export const ConfigureRelationshipMatch = (props) => {
  const {
    dataIndexOptions,
    relationshipField,
    relationshipMatchConfig,
    relationshipMatchReport,
    service,
  } = props;

  const [isMulti, setIsMulti] = useState(relationshipMatchConfig.dataIndexes.map((dataIndexes) => dataIndexes.length > 1));

  if (relationshipField.type !== "SET") {
    throw new Error("relationshipField should be SET");
  }

  /**
   * @type {JSX.Element[]}
   */
  const successes = [];

  if (relationshipMatchReport.matched.valid.length) {
    successes.push(
      <React.Fragment key="update_success">
        connect <strong>{relationshipMatchReport.matched.valid.flatMap(([, rowIndexes]) => rowIndexes).length}</strong> matched relationship(s)
      </React.Fragment>
    );
  }

  /**
   * @type {JSX.Element[]}
   */
  const issues = [];

  const hasSelections = !!relationshipMatchConfig.dataIndexes.flat().length;

  if (!hasSelections) {
    issues.push(
      <>
        <div className="mb-2">
          Must make a selection to match the relationship field.
        </div>
      </>
    );
  }

  if (hasSelections && relationshipMatchReport.unmatched.length) {
    /** @type{Set<number>} */
    const unmatchedRowIndexes = new Set(
      relationshipMatchReport.unmatched.flatMap(([, rowIndexes]) => rowIndexes)
    );

    issues.push(
      <>
        <div className="mb-2">
          Cannot find matches for values:<br />
          <strong>{relationshipMatchReport.unmatched.map(([value]) => value).join(", ")}</strong>
        </div>
        <div className="mb-2">
          On import rows:<br />
          <strong>{[...unmatchedRowIndexes].map(index => index + 1).join(", ")}</strong>
        </div>
      </>
    );
  }

  if (hasSelections && !successes.length) {
    issues.push(
      <div>No matches were found. This import will not relate any {relationshipField.name} records.</div>
    );
  }

  // NOTE: there are duplicates in CRM that were matched to,
  // but we can't do anything with them.
  //
  // Reconcile the records in the system to continue.
  if (hasSelections && relationshipMatchReport.matched.duplicate.length) {
    issues.push(
      <>
        <div className="mb-1">Relationships matched to multiple records in CRM:</div>
        {relationshipMatchReport.matched.duplicate.map(([key, rowIndexes]) => {
          return (
            <div>
              <strong>{key}</strong> exists on multiple CRM records and matched to import rows:{" "}
              {rowIndexes.map((index) => index + 1).join(", ")}
            </div>
          );
        })}
        <div className="mb-1">matches must be unique to relate.</div>
      </>
    );
  }

  return (
    <div className="w-full">
      {relationshipMatchConfig.dataIndexes.map((dataIndexes, index) => {
        return (
          <React.Fragment key={index}>
            <div className="mb-2 flex items-center">
              <Checkbox
                checked={isMulti[index]}
                onChange={() => {
                  if (isMulti[index] && dataIndexes.length > 1) {
                    service.setRelationshipMatchKey({
                      dataIndex: relationshipField.dataIndex,
                      index,
                      value: [dataIndexes[0]],
                    });
                  }

                  const next = [...isMulti];
                  next.splice(index, 1, !isMulti[index]);

                  setIsMulti(next);
                }}
              />
              <div className={"mx-2 w-[112px]"}>combination of</div>
              <Select
                className="flex-1"
                mode={isMulti[index] ? "multiple" : undefined}
                value={dataIndexes}
                options={dataIndexOptions}
                onChange={(value) => {
                  service.setRelationshipMatchKey({
                    dataIndex: relationshipField.dataIndex,
                    index,
                    value: isArray(value) ? value : [value],
                  });
                }}
              />
              {service.canRemoveRelationshipMatchKey()(relationshipField.dataIndex) ? <Button
                rootClassName="ml-2"
                shape="circle"
                size="small"
                icon={<MinusOutlined />}
                onClick={() => {
                  const nextIsMulti = [...isMulti];
                  nextIsMulti.splice(index, 1);
                  setIsMulti(nextIsMulti);

                  service.removeRelationshipMatchKey({
                    index,
                    dataIndex: relationshipField.dataIndex,
                  });
                }}
              /> : null}
            </div>
          </React.Fragment>
        )
      })}
      <div className="flex justify-start mb-2">
        <Button
          disabled={!service.canAddRelationshipMatchKey()(relationshipField.dataIndex)}
          onClick={() => service.addRelationshipMatchKey(relationshipField.dataIndex)}
          type="primary"
          icon={<PlusOutlined />}
          size="small"
        >Add</Button>
      </div>

      {issues.length
        ? issues.map((issue, index) => {
          return (
            <Alert
              message={issue}
              type="warning"
              className="!mb-3"
              key={index}
            />
          );
        })
        : null}

      {successes.length ? (
        <Alert
          type="success"
          message={<div>Import will {successes} records.</div>}
          className="!mb-2"
        />
      ) : null}
    </div>
  );
};
