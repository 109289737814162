// @ts-check

import { findField } from "./find-field";
import { CREATE_USER_DEFINED_FIELD_OPTION } from "./identify-headers";

/** @typedef {import("./domain").HeaderValues<string>} Result

/**
 * @param {Object} o
 * @param {string | null} o.dataIndex
 * @param {import("./domain").ImportField[]} o.importFields
 * @returns {boolean}
 */
export const isRelationshipField = ({ dataIndex, importFields }) => {
  if (!dataIndex || dataIndex === CREATE_USER_DEFINED_FIELD_OPTION.value) {
    return false;
  }

  const field = findField(dataIndex)(importFields);

  return field.type === "SET";
};

/**
 * @param {import("./domain").ImportField[]} importFields
 */
export const isRelationshipDataIndex = (importFields) => {
  /**
   * @param {string | null} dataIndex
   * @returns {dataIndex is string}
   */
  const hof = (dataIndex) => {
    return isRelationshipField({
      dataIndex,
      importFields,
    });
  };

  return hof;
};

/**
 * @param {Object} o
 * @param {(string | null)[]} o.headerDataIndexes
 * @param {import("./domain").ImportField[]} o.importFields
 * @param {Record<string, import("./domain").RelationshipMatchConfig >} [o.relationshipMatchConfigs]
 * @returns {Result}
 */
export const getUnconfiguredRelationships = ({
  headerDataIndexes,
  importFields,
  relationshipMatchConfigs = {},
}) => {
  /**
   * @param {Result} acc
   * @param {string | null} dataIndex
   * @returns {Result}
   */
  const reducer = (acc, dataIndex) => {
    if (dataIndex && dataIndex !== CREATE_USER_DEFINED_FIELD_OPTION.value) {
      const field = findField(dataIndex)(importFields);

      if (
        field.type === "SET" &&
        !(relationshipMatchConfigs[dataIndex]?.dataIndexes ?? []).filter(
          (dataIndexes) => {
            return dataIndexes.length;
          }
        ).length
      ) {
        acc.push(dataIndex);
        return acc;
      }
    }

    acc.push(null);
    return acc;
  };

  return headerDataIndexes.reduce(reducer, []);
};
