// @ts-check

import set from "lodash/set";

import { findField } from "./find-field";
import { getRowMatchKeys } from "./configure-matches/get-row-matches";
import { getSelectFieldOptionsCache } from "./get-select-field-options-cache";
import { normalize } from "./are-headers-equal";
import { splitMultiMatchKeys } from "./configure-matches/get-relationship-match-keys";
import { areAllRequiredFieldsSelected } from "./configure-matches/are-all-required-fields-selected";

/**
 * @param {Object} o
 * @param {import("./domain").ImportField[]} o.importFields
 * @param {import("./domain").CSVUpload} o.upload
 * @param {(string | null)[]} o.headerDataIndexes
 * @param {import("./domain").EntityMatchConfig} o.entityMatchConfig
 *
 * @param {{
 *  entitiesByMatchKeys: Record<string, string[]>;
 *  rowsByMatchKeys: Record<string, number[]>;
 * }} o.entityMatchKeys
 *
 * @param {Record<string, {
 *  entitiesByMatchKeys: Record<string, string[]>;
 * }>} o.relationshipMatchKeys
 *
 * @returns {{
 *  entities: object[];
 *  invalidRows: import("./domain").InvalidRow[];
 * }}
 */

export const reduceImportEntities = ({
  // NOTE: importFields should have been reduced with newUserDefinedFields already
  importFields,
  upload,
  headerDataIndexes,
  entityMatchConfig,
  entityMatchKeys,
  relationshipMatchKeys,
}) => {
  const selectFieldOptionsCache = getSelectFieldOptionsCache({
    headerDataIndexes,
    importFields,
  });

  const { entitiesByMatchKeys, rowsByMatchKeys } = entityMatchKeys;

  const canCreateIfNoMatch = areAllRequiredFieldsSelected({
    importFields,
    headerDataIndexes,
  });

  /**
   * @type {import("./domain").InvalidRow[]}
   */
  const invalidRows = [];

  // TODO:
  // - validate that rows to be created have all
  // required fields
  // - validate all fields in general to ensure they
  // are valid (and won't fail on server side)

  /**
   * @param {object[]} acc
   * @param {string[]} row
   *
   * @returns {object[]}
   */
  const reducer = (acc, row) => {
    const entity = {
      userDefinedFields: {},
    };

    const rowMatchKeys = getRowMatchKeys({
      row,
      headerDataIndexes,
      dataIndexes: entityMatchConfig.dataIndexes,
    });

    // NOTE: There are multiple rows with the same
    // entity match key for all possible keys.
    // Cannot do anything with it.
    if (
      rowMatchKeys.length &&
      rowMatchKeys.every((rowMatchKey) => {
        return (rowsByMatchKeys[rowMatchKey] ?? []).length > 1;
      })
    ) {
      return acc;
    }

    // NOTE:
    // - check if there is exactly 1 entity match
    // - more than one entity match? ambiguous, ignore
    // - exactly 1, add _id of match to update
    // - none, create if createIfNoMatch is true
    const potentialIds = [
      ...new Set(
        rowMatchKeys.flatMap((rowMatchKey) => {
          if ((rowsByMatchKeys[rowMatchKey] ?? []).length > 1) {
            return [];
          }

          return entitiesByMatchKeys[rowMatchKey] ?? [];
        })
      ),
    ];

    if (potentialIds.length > 1) {
      return acc;
    }

    // TODO: confirm all required fields are present
    if (
      // NOTE: no matches, so attempting to create
      !potentialIds.length &&
      // NOTE: must have required fields and
      // option selected to create
      (!canCreateIfNoMatch || !entityMatchConfig.createIfNoMatch)
    ) {
      return acc;
    }

    if (potentialIds.length === 1) {
      entity._id = potentialIds[0];
    }

    for (const [index, dataIndex] of Object.entries(headerDataIndexes)) {
      if (!dataIndex) {
        continue;
      }

      const raw = row[Number(index)];
      const field = findField(dataIndex)(importFields);

      if (field.type === "SELECT") {
        const options = selectFieldOptionsCache[Number(index)];

        if (!options) {
          throw new Error(
            `Select field at headerIndex = ${index} has no options.`
          );
        }

        const id = options[normalize(raw)];

        // NOTE: all values should have a normalized
        // option by now, or, if not, the user chose to
        // ignore that value.
        if (!id) {
          continue;
        }

        set(entity, dataIndex, id);
      } else if (field.type === "TEXT") {
        // TODO: validate length?
        set(entity, dataIndex, raw);
      } else if (field.type === "SET") {
        const matchKeys = splitMultiMatchKeys({
          value: raw,
        });

        if (field.relationship.cardinality === "one" && matchKeys.length > 1) {
          continue;
        }

        /** @type {string[]} */
        const _ids = [];

        for (const key of matchKeys) {
          const potentialIds =
            relationshipMatchKeys[dataIndex]?.entitiesByMatchKeys?.[key];

          if (potentialIds && potentialIds.length === 1) {
            _ids.push(potentialIds[0]);
          }
        }

        set(entity, dataIndex, _ids);
      } else if (field.type === "LINK") {
        // TODO: validate
        set(entity, dataIndex, raw);
      } else if (field.type === "DATE") {
        // TODO: validate and map?
        set(entity, dataIndex, raw);
      } else if (field.type === "RANGE") {
        // TODO: implement
        set(entity, dataIndex, raw);
      } else if (field.type === "DOLLAR") {
        // TODO: validate and map
        set(entity, dataIndex, raw);
      } else if (field.type === "NUMBER") {
        // TODO: implement
        set(entity, dataIndex, raw);
      } else if (field.type === "PERCENT") {
        // TODO: implement
        set(entity, dataIndex, raw);
      } else if (field.type === "ACCOUNT_STATE") {
        // TODO: implement
        set(entity, dataIndex, raw);
      } else if (field.type === "ACTIVITY_DATE") {
        // TODO: implement
        set(entity, dataIndex, raw);
      } else if (field.type === "FOLLOWUP_DATE") {
        // TODO: implement
        set(entity, dataIndex, raw);
      } else if (field.type === "CALCULATED") {
        // TODO: implement
        set(entity, dataIndex, raw);
      }
    }

    return [...acc, entity];
  };

  return {
    entities: upload.rows.reduce(reducer, []),
    invalidRows,
  };
};
