// @ts-check

import Parse from "papaparse";
import { Button, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import React, { useState } from "react";

import { smartTrim } from "./smart-trim";

/**
 * @typedef {Object} File
 *
 * @prop {string} name
 * @prop {"done" | "uploading"} status
 * @prop {string} uid
 */

/**
 * @param {File[]} initialState
 */
const useFileList = (initialState) => {
  /** @type {import("@evolved/domain").UseState<File[]>} */
  const [state, setState] = useState(initialState);

  /**
   * @param {{
   *  name: string;
   *  status?: "done" | "uploading";
   * }} file 
   */
  const set = (file) => {
    const { name, status = "done" } = file;
    setState([{ name, status: status, uid: "-1" }]);
  };

  const clear = () => {
    setState([]);
  };

  return {
    state,
    set,
    clear,
  };
};

/**
 * @callback OnSuccess
 *
 * @param {import("./domain").CSVUpload} upload
 */

/**
 * @typedef {Object} Props
 * 
 * @prop {OnSuccess} onSuccess
 * @prop {(() => void) | null} onNext
 * @prop {(error: Error) => void} onError
 */

/**
 * @param {Props} props
 */
export const FileUpload = (props) => {
  const {
    onSuccess,
    onNext,
    onError,
  } = props;

  const fileList = useFileList([]);

  /**
   * @param {import("antd/es/upload").RcFile} file
   */
  const onDrop = async (file) => {
    fileList.set({ name: file.name, status: "uploading" });

    try {
      Parse.parse(await file.text(), {
        header: false,
        skipEmptyLines: true,
        complete: (results) => {
          /** @type {string[][]} */
          const rows = results.data;

          let headers = rows[0];
          if (!headers) {
            throw new Error("Must have a header row.");
          }

          headers = headers.map(smartTrim);

          fileList.set({ name: file.name, status: "done" });
          onSuccess({ headers, rows: rows.slice(1) });
        }
      });
    } catch (error) {
      fileList.clear();
      onError(error);
    }
  };

  return (
    <>
      <Upload.Dragger
        accept=".csv"
        customRequest={async ({ file, onSuccess }) => {
          await onDrop(
            /** @type {import("antd/es/upload").RcFile} */
            (file)
          );

          onSuccess?.("ok");
        }}
        fileList={fileList.state}
        onRemove={fileList.clear}
      >
        <p className="ant-upload-drag-icon">
          <UploadOutlined />
        </p>
        <p className="ant-upload-text" style={{ margin: "4px 24px" }}>
          Drop your .csv file here.
        </p>
      </Upload.Dragger>
      {onNext && (
        <div className="flex justify-end mt-2">
          <Button type="primary" onClick={onNext}
          >Next</Button>
        </div>
      )}
    </>
  )
};
