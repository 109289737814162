// @ts-check

/** @satisfies {Readonly<import("@evolved/domain").EntityType[]>} */
export const supportedEntityTypes = Object.freeze([
  "ACCOUNT",
  "ACTIVITY",
  "CONTACT",
  "OPPORTUNITY",
  "VENDOR",
]);
/**
 * @typedef {typeof supportedEntityTypes[number]} ImportableEntityTypes
 */

/**
 * @template T
 * @typedef {(T | null)[]} HeaderValues
 */

/**
 * @typedef {Object} ImportFieldBase
 *
 * @prop {string} dataIndex
 * @prop {string} name
 * @prop {string} title - DEPRECATED use name instead
 * @prop {boolean} [isRequired]
 *
 * @typedef {ImportFieldBase & import("@evolved/domain").FieldTypeConfigs} ImportField
 */

/**
 * @typedef {Object} CSVUpload
 *
 * @prop {string[]} headers
 * @prop {string[][]} rows
 */

/**
 * @typedef {Object} EntityMatchConfig
 *
 * @prop {string[][]} dataIndexes - supports array to support contact firstName + lastName
 * @prop {boolean} createIfNoMatch - only possible if all required fields for the entity
 * are present.
 */

/**
 * @typedef {Object} RelationshipMatchConfig
 *
 * @prop {string[][]} dataIndexes - supports array to support contact firstName + lastName
 */

/**
 * @typedef {{
 *  upload: CSVUpload;
 *  headerDataIndexes: HeaderValues<string>;
 *  newUserDefinedFields: (import("@evolved/domain").UserDefinedField | null)[];
 *  entityMatchConfig: EntityMatchConfig;
 *  relationshipMatchConfigs: Record<string, RelationshipMatchConfig>;
 * }} InitializedImportState
 *
 */

/**
 * Import state user selections.
 *
 * @typedef {{} | InitializedImportState} ImportState
 */

/**
 * @typedef {Object} InvalidRow
 *
 * @prop {number} index
 * @prop {({ errors: { message: string }[] } | null)[]} invalidHeaders
 */

/**
 * @typedef {Object} UploadFileState
 *
 * @prop {"upload_file"} stepId
 * @prop {({type: "duplicate_headers", duplicateHeaders: string[]})[]} [errors]
 */

/**
 * @typedef {Object} ConfigureHeaderDataIndexesState
 *
 * @prop {"configure_header_data_indexes"} stepId
 * @prop {CSVUpload} upload
 * @prop {({value: string; label: string;}[] | null)[]} headerOptions
 * @prop {(string | null)[]} headerDataIndexes
 * @prop {(string | null)[]} errors
 */

/**
 * Header index based array of userDefinedFields
 * that match what is in the headerDataIndexes that
 * currently don't exist on the organization.
 *
 * @typedef {Object} ConfigureUserDefinedFieldsState
 *
 * @prop {"create_user_defined_fields"} stepId
 * @prop {CSVUpload} upload
 * @prop {(import("@evolved/domain").UserDefinedField | null)[]} newUserDefinedFields
 */

/**
 * - user optionally selects from the
 *   available data indexes, which they will
 *   use to match (some can be multiple like f/lname)
 *
 * - if no selection, all are assumed to be created,
 *   therefore, the required fields are required,
 *   so validate those against
 *
 * - if selection, validate matches/what will be
 *   created (if create if unmatched) and show all
 *   that will be ignored, etc.
 *
 * NOTE: we could ask questions about updating
 * earlier in the process, so users don't have
 * to go back. But, this is an edge case, they
 * will very likely import with a name.
 *
 * What gets filtered out is stateless,
 * we are just letting the user know what
 * will happen.
 *
 * @typedef {Object} ConfigureEntityMatchState
 *
 * @prop {"configure_entity_match"} stepId
 * @prop {EntityMatchConfig} entityMatchConfig
 * @prop {{label: string; value: string;}[]} dataIndexOptions
 * @prop {{name: string; dataIndex: string;}[]} requiredFields
 * @prop {boolean} canCreateIfNoMatch
 * @prop {Record<string, string[]>} o.entitiesByMatchKeys
 * @prop {Record<string, number[]>} o.rowsByMatchKeys
 * @prop {import("./configure-matches/reduce-entity-match-report").EntityMatchReport} entityMatchReport
 */

/**
 * @typedef {Object} ConfigureRelationshipsMatchState
 *
 * @prop {"configure_relationships_matches"} stepId
 * @prop {CSVUpload} upload
 * @prop {Record<string, RelationshipMatchConfig>} relationshipMatchConfigs
 * @prop {Record<string, {label: string; value: string;}[]>} dataIndexOptions
 * @prop {Record<string, {
 *  entitiesByMatchKeys: Record<string, string[]>;
 *  rowsByMatchKeys: Record<string, number[]>;
 * }>} relationshipMatchKeys
 * @prop {Record<string, import("./configure-matches/reduce-relationship-match-report").RelationshipMatchReport>} relationshipMatchReports
 */

/**
 * @typedef {Object} ResolveInvalidFieldsState
 *
 * @prop {"resolve_invalid_fields"} stepId
 * @prop {InvalidRow[]} invalidRows
 */

/**
 * @typedef {Object} ConfirmState
 *
 * @prop {"confirm"} stepId
 * @prop {object[]} entities
 * @prop {import("@evolved/domain").UserDefinedField[]} newUserDefinedFields
 */

/**
 * @typedef {(
 *   | UploadFileState
 *   | ConfigureHeaderDataIndexesState
 *   | ConfigureUserDefinedFieldsState
 *   | ConfigureEntityMatchState
 *   | ConfigureRelationshipsMatchState
 *   | ResolveInvalidFieldsState
 *   | ConfirmState
 * )} ReducedImportSteps
 */

/**
 * @typedef {ReducedImportSteps['stepId']} StepIds
 */

/**
 * @param {ImportState} state
 *
 * @returns {state is InitializedImportState}
 */
export const isStateInitialized = (state) => {
  return "upload" in state;
};

export default undefined;
