// @ts-check

import React, { useState } from "react";
import { Button, Segmented } from "antd";
import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";

import { ConfigureRelationshipMatch } from "./configure-relationship-match";
import { colors } from "@evolved/constants";
import { findField } from "../find-field";

/**
 * @typedef {Object} Props
 *
 * @prop {import("../domain").ImportField[]} importFields
 * @prop {import("../import-state/import-state").ImportStateService} service
 * @prop {import("../domain").ConfigureRelationshipsMatchState} step
 *
 * @prop {() => void} onBack
 * @prop {(() => void) | null} onNext
 */

/**
 * @param {Props} props
 */
export const ConfigureRelationshipMatches = (props) => {
  const {
    importFields,
    service,
    step,
    onNext,
    onBack,
  } = props;

  const dataIndexes = Object.keys(step.relationshipMatchConfigs);

  const defaultIndex = dataIndexes.find(dataIndex => {
    return step.relationshipMatchConfigs[dataIndex].dataIndexes.length === 0;
  }) ?? dataIndexes[0];

  const [currentDataIndex, setCurrentDataIndex] = useState(
    defaultIndex
  );

  const options = dataIndexes.map((dataIndex) => {
    const icon = (
      step.relationshipMatchConfigs[dataIndex].dataIndexes.some(
        (_dataIndexes) => _dataIndexes.length)
    ) ?
      <CheckCircleTwoTone style={{ marginLeft: "4px" }} twoToneColor={colors.SUCCESS} /> :
      <CloseCircleTwoTone style={{ marginLeft: "4px" }} twoToneColor={colors.URGENT} />
      ;

    return {
      label: <div>{findField(dataIndex)(importFields).name}{icon}</div>,
      value: dataIndex,
    };
  });

  return (
    <>
      <div className="mb-1">
        Which field(s) on the related entity match CRM records to your import rows?
      </div>
      <div className="mb-2">
        <strong>Unmatched relationships will be ignored.</strong>
      </div>
      <div className="mb-4">
        <Segmented
          options={options}
          value={currentDataIndex}
          onChange={setCurrentDataIndex}
          block
        />
      </div>
      <ConfigureRelationshipMatch
        {...{
          dataIndexOptions: step.dataIndexOptions[currentDataIndex],
          relationshipField: findField(currentDataIndex)(importFields),
          relationshipMatchConfig: step.relationshipMatchConfigs[currentDataIndex],
          relationshipMatchReport: step.relationshipMatchReports[currentDataIndex],
          service,
        }}
      />
      <div className="flex justify-end">
        <Button className="mr-2" onClick={onBack}>Back</Button>
        <Button type="primary" disabled={!onNext} onClick={onNext ?? undefined}
        >Next</Button>
      </div>
    </>
  );
};
