// @ts-check

import { CREATE_USER_DEFINED_FIELD_OPTION } from "../identify-headers";
import { areAllRequiredFieldsSelected } from "../configure-matches/are-all-required-fields-selected";
import { getDefaultHeaderDataIndexes } from "../get-default-header-data-indexes";
import { getDuplicateHeaders } from "../get-duplicate-headers";
import { getHeaderOptions } from "./get-header-options";
import { getImportFields } from "../get-import-fields";
import { getRequiredFields } from "../configure-matches/get-required-fields";
import {
  isEntityMatchConfigUnsynced,
  syncMatchConfigDataIndexes,
} from "./sync-entity-match-config-data-indexes";
import { isStateInitialized } from "../domain";
import { reduceEntitiesByMatchKeys } from "../configure-matches/get-entity-matches";
import { reduceEntityMatchReport } from "../configure-matches/reduce-entity-match-report";
import { reduceImportEntities } from "../reduce-import-entities";
import {
  getInvalidCardinalityRows,
  reduceRowsByMatchKeys,
  reduceRowsByMultiMatchKeys,
} from "../configure-matches/get-row-matches";
import {
  getUnconfiguredRelationships,
  isRelationshipDataIndex,
} from "../get-unconfigured-relationships";
import { getDataIndexOptions } from "../configure-matches/get-data-index-options";
import { findField } from "../find-field";
import { reduceRelationshipMatchReport } from "../configure-matches/reduce-relationship-match-report";
import { isMatchableField } from "../configure-matches/is-matchable-field";

/**
 * Reduces the current state to determine valid
 * states for the user to navigate to.
 *
 * If user state has not specified a stepId,
 * then push the user to the last state.
 *
 * @param {Object} o
 * @param {import("../domain").ImportState} o.state
 * @param {import("../domain").ImportField[]} o.importFields
 * @param {import("@evolved/domain").UserDefinedField[]} o.userDefinedFields
 * @param {import("../domain").ImportableEntityTypes} o.entityType
 * @param {import("../use-entity-cache").EntityCache} o.entityCache
 *
 * @returns {import("../domain").ReducedImportSteps[]}
 *
 */
export const reduceSteps = ({
  state,
  importFields,
  userDefinedFields,
  entityType,
  entityCache,
}) => {
  /** @type {import("../domain").UploadFileState} */
  const uploadFileStep = { stepId: "upload_file" };

  /** @type {import("../domain").ReducedImportSteps[]} */
  const steps = [uploadFileStep];

  if (!isStateInitialized(state)) {
    return steps;
  }

  const duplicateHeaders = getDuplicateHeaders(state.upload.headers);

  if (duplicateHeaders?.length) {
    uploadFileStep.errors = [
      {
        type: "duplicate_headers",
        duplicateHeaders,
      },
    ];

    return steps;
  }

  // TODO: rename getDirectMatchDataIndexes
  const directMatchDataIndexes = getDefaultHeaderDataIndexes({
    importFields,
    headers: state.upload.headers,
  });

  const headerDataIndexes = state.headerDataIndexes.map((dataIndex, index) => {
    return directMatchDataIndexes[index] ?? dataIndex;
  });

  const headerOptions = getHeaderOptions({
    headerDataIndexes,
    importFields,
  }).map((options, index) => {
    // NOTE: If there is a direct match, don't
    // give any options, don't want to give an
    // opportunity for a mistake here.
    //
    // User can change the name in the upload
    // data if it's different.
    if (directMatchDataIndexes[index]) {
      return null;
    }

    return options;
  });

  /** @type {(string | null)[]} */
  const configureHeaderDataIndexesErrors = headerDataIndexes.map(
    (dataIndex, index) => {
      if (!dataIndex) {
        return null;
      }

      const field = findField(dataIndex)(importFields);

      if (field.type !== "SET" || field.relationship.cardinality === "many") {
        return null;
      }

      const invalidRows = getInvalidCardinalityRows({
        index,
        rows: state.upload.rows,
      });

      if (invalidRows.length === 0) {
        return null;
      }

      return `Field "${
        field.name
      }" only supports one related entity. The following rows have more than one and will be ignored: ${invalidRows.map(
        (index) => index + 1
      )}.`;
    }
  );

  steps.push({
    stepId: "configure_header_data_indexes",
    upload: state.upload,
    headerOptions,
    headerDataIndexes,
    errors: configureHeaderDataIndexesErrors,
  });

  // NOTE: At least one selection should have been made.
  if (!headerDataIndexes.filter((v) => !!v).length) {
    return steps;
  }

  if (
    headerDataIndexes.some(
      (dataIndex) => dataIndex === CREATE_USER_DEFINED_FIELD_OPTION.value
    )
  ) {
    steps.push({
      stepId: "create_user_defined_fields",
      upload: state.upload,
      newUserDefinedFields: state.newUserDefinedFields,
    });
  }

  const entitiesByMatchKeys = reduceEntitiesByMatchKeys({
    entities: entityCache[entityType],
    dataIndexes: state.entityMatchConfig.dataIndexes,
  });

  const rowsByMatchKeys = reduceRowsByMatchKeys({
    dataIndexes: state.entityMatchConfig.dataIndexes,
    headerDataIndexes,
    rows: state.upload.rows,
  });

  const entityMatchReport = reduceEntityMatchReport({
    entitiesByMatchKeys,
    rowsByMatchKeys,
    rows: state.upload.rows,
  });

  const requiredFields = getRequiredFields({ importFields });
  const canCreateIfNoMatch = areAllRequiredFieldsSelected({
    importFields,
    headerDataIndexes,
  });

  steps.push({
    stepId: "configure_entity_match",
    canCreateIfNoMatch,
    dataIndexOptions: getDataIndexOptions({
      headerDataIndexes,
      importFields,
    }),
    entityMatchConfig: state.entityMatchConfig,
    entityMatchReport,
    entitiesByMatchKeys,
    rowsByMatchKeys,
    requiredFields,
  });

  // NOTE: if the reducer was step aware, then
  // it could enforce that the sync was run
  // before configure_entity_match could be
  // entered.
  // TODO: this still doesn't sit right,
  // could a user get stuck here?
  if (
    isEntityMatchConfigUnsynced({
      entityMatchConfig: state.entityMatchConfig,
      headerDataIndexes,
    })
  ) {
    return steps;
  }

  // NOTE: configured to not do anything with import
  // rows. Must be able to update or create.
  if (
    !state.entityMatchConfig.dataIndexes.some(
      (dataIndexes) => dataIndexes.length
    ) &&
    (!state.entityMatchConfig.createIfNoMatch ||
      !areAllRequiredFieldsSelected({
        importFields,
        headerDataIndexes,
      }))
  ) {
    return steps;
  }

  /**
   * @type {Record<string, {
   *  entitiesByMatchKeys: Record<string, string[]>;
   *  rowsByMatchKeys: Record<string, number[]>;
   * }>}
   */
  const relationshipMatchKeys = {};

  const relationshipDataIndexes = headerDataIndexes.filter(
    isRelationshipDataIndex(importFields)
  );

  /**
   * @type {Record<string, import("../configure-matches/reduce-relationship-match-report").RelationshipMatchReport>}
   */
  const relationshipMatchReports = {};

  /**
   * @type {Record<string, {label: string; value: string;}[]>}
   */
  const relationshipMatchOptions = {};

  if (relationshipDataIndexes.length) {
    const relationshipMatchConfigs = {
      ...state.relationshipMatchConfigs,
    };

    for (const dataIndex of relationshipDataIndexes) {
      const field = findField(dataIndex)(importFields);

      if (field.type !== "SET") {
        throw new Error("relationship field should be SET");
      }

      const relationshipImportFields = getImportFields({
        entityType: field.relationship.entityType,
        userDefinedFields,
      });

      if (!relationshipMatchConfigs[dataIndex]) {
        // NOTE: apply default dataIndexes if nothing yet configured.
        relationshipMatchConfigs[dataIndex] = {
          dataIndexes: syncMatchConfigDataIndexes({
            importFields: relationshipImportFields,
            matchConfig: { dataIndexes: [] },
          }),
        };
      }

      const relationshipMatchConfig = relationshipMatchConfigs[dataIndex];

      if (!relationshipMatchConfig) {
        throw new Error(
          `relationshipMatchConfig should have been initialized for dataIndex = "${dataIndex}"`
        );
      }

      const entitiesByMatchKeys = relationshipMatchConfig.dataIndexes.flat()
        .length
        ? reduceEntitiesByMatchKeys({
            entities: entityCache[field.relationship.entityType],
            dataIndexes: relationshipMatchConfig.dataIndexes,
          })
        : {};

      const rowsByMatchKeys = relationshipMatchConfig.dataIndexes.flat().length
        ? reduceRowsByMultiMatchKeys({
            cardinality: field.relationship.cardinality,
            dataIndex: field.dataIndex,
            headerDataIndexes,
            rows: state.upload.rows,
          })
        : {};

      relationshipMatchKeys[dataIndex] = {
        entitiesByMatchKeys,
        rowsByMatchKeys,
      };

      relationshipMatchReports[dataIndex] = reduceRelationshipMatchReport({
        entitiesByMatchKeys,
        rowsByMatchKeys,
      });

      const matchableFields = relationshipImportFields.filter(isMatchableField);

      relationshipMatchOptions[dataIndex] = matchableFields.map(
        ({ dataIndex, name }) => {
          return {
            value: dataIndex,
            label: name,
          };
        }
      );
    }

    steps.push({
      stepId: "configure_relationships_matches",
      upload: state.upload,
      relationshipMatchConfigs,
      dataIndexOptions: relationshipMatchOptions,
      relationshipMatchKeys,
      relationshipMatchReports,
    });

    const unconfiguredRelationships = getUnconfiguredRelationships({
      importFields,
      relationshipMatchConfigs,
      headerDataIndexes,
    });

    if (unconfiguredRelationships.filter((v) => !!v).length) {
      return steps;
    }
  }

  const newUserDefinedFields = (state.newUserDefinedFields ?? []).filter(
    /** @returns {field is import("@evolved/domain").UserDefinedField} */
    (field) => !!field
  );

  // TODO:
  // - do we need to use canCreateIfNoMatch to override
  // entityMatchConfig.createIfNoMatch setting,
  // or does reduceImportEntities already do this?
  const { invalidRows, entities } = reduceImportEntities({
    importFields: getImportFields({
      userDefinedFields: [...userDefinedFields, ...newUserDefinedFields],
      entityType,
    }),
    upload: state.upload,
    headerDataIndexes: headerDataIndexes.map((dataIndex, index) => {
      if (dataIndex === CREATE_USER_DEFINED_FIELD_OPTION.value) {
        const newUdf = state.newUserDefinedFields?.[index];

        if (!newUdf) {
          throw new Error(
            `Something went wrong. Creating user defined field for column ${
              index + 1
            } failed.`
          );
        }

        return `userDefinedFields.${newUdf.id}`;
      }

      return dataIndex;
    }),
    entityMatchConfig: state.entityMatchConfig,
    entityMatchKeys: {
      entitiesByMatchKeys,
      rowsByMatchKeys,
    },
    relationshipMatchKeys,
  });

  if (invalidRows.length > 0) {
    steps.push({
      stepId: "resolve_invalid_fields",
      invalidRows,
    });

    return steps;
  }

  steps.push({
    stepId: "confirm",
    entities,
    newUserDefinedFields,
  });

  return steps;
};
