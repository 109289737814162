// @ts-check

/**
 * @param {Object} o
 * @param {Record<string, string[]>} o.entitiesByMatchKeys
 * @param {Record<string, number[]>} o.rowsByMatchKeys
 */
export const reduceRelationshipMatchReport = ({
  entitiesByMatchKeys,
  rowsByMatchKeys,
}) => {
  // NOTE: each row has multiple things to match and it may
  // match one or many or none
  // - the index may show up multiple times

  /**
   * @param {{
   *  unmatched: [string, number[]][];
   *  matched: {
   *    valid: [string, number[]][];
   *    duplicate: [string, number[]][];
   *  };
   * }} acc
   * @param {[string, number[]]} rowsByMatchKey
   */
  const reduceResult = (acc, [key, rowIndexes]) => {
    const match = entitiesByMatchKeys[key];
    if (!match) {
      acc.unmatched.push([key, rowIndexes]);
      return acc;
    }

    if (match.length > 1) {
      acc.matched.duplicate.push([key, rowIndexes]);
      return acc;
    }

    acc.matched.valid.push([key, rowIndexes]);
    return acc;
  };

  return Object.entries(rowsByMatchKeys).reduce(reduceResult, {
    unmatched: [],
    matched: {
      valid: [],
      duplicate: [],
    },
  });
};

/**
 * @typedef {ReturnType<typeof reduceRelationshipMatchReport>} RelationshipMatchReport
 */
