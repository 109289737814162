/**
 * @param {import("../domain").ImportField} field
 */
export const isMatchableField = (field) => {
  if (field.type !== "LINK" && field.type !== "TEXT") {
    return false;
  }

  return !!field.canMatchEntityBy;
};
