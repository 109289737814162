// @ts-check

import React from "react";
import { Alert, Button, Select } from "antd";
import {
  ArrowRightOutlined
} from "@ant-design/icons";

// TODO: this probably belongs in domain
export const CREATE_USER_DEFINED_FIELD_OPTION = Object.freeze({
  label: "Create User Defined Field",
  value: "create_user_defined_field",
});

/** @type {React.FC<{
 *  upload: import("./domain").CSVUpload;
 *  headerOptions: ({value: string; label: string;}[] | null)[];
 *  headerDataIndexes: (string | null)[];
 *  errors: (string | null)[];
 *  setHeaderDataIndex: (o: {value: string | null; index: number;}) => void; 
 *  onBack: () => void;
 *  onNext: (() => void) | null;
 * }>} */
export const IdentifyHeaders = (props) => {
  const { upload, headerOptions, headerDataIndexes, errors, setHeaderDataIndex, onBack, onNext } = props;

  return (
    <div className="w-full">
      <div className={"flex w-full justify-between mb-4"}>
        <div className="w-[50%] font-bold">
          CSV Header</div>
        <div className="w-[50%] font-bold">
          CRM Field
        </div>
      </div>
      {upload.headers.map((header, index) => {
        const dataIndex = headerDataIndexes[index];
        const options = headerOptions[index];

        return (
          <>
            <div className={"flex w-full justify-between mb-2"} key={header}>
              <div className="w-[50%]">{header}</div>
              <div className="w-[50%] flex">
                {options && (
                  <>
                    <ArrowRightOutlined className="mr-4" />
                    <Select
                      allowClear
                      onClear={() => {
                        setHeaderDataIndex({
                          value: null,
                          index,
                        });
                      }}
                      placeholder="skip"
                      className="grow"
                      value={dataIndex}
                      onChange={(value) => {
                        setHeaderDataIndex({
                          value,
                          index,
                        });
                      }}
                      options={options}
                    />
                  </>
                )}
                {!options && (
                  <strong>Exact Match</strong>
                )}
              </div>
            </div>
            {errors[index] ? (
              <Alert
                message={errors[index]}
                type="warning"
                className="!mb-3"
              />
            ) : null}
          </>
        );
      })}
      <div className="flex justify-end">
        <Button className="mr-2" onClick={onBack}>
          Back
        </Button>
        <Button
          type="primary"
          disabled={!onNext}
          onClick={onNext ?? undefined}
        >
          Next
        </Button>
      </div>
    </div>
  );
};
