import { getPriorityMatchField } from "./get-priority-match-field";
import { isMatchableField } from "./is-matchable-field";

/**
 * @param {Object} o
 * @param {import("../domain").ImportField[]} o.importFields
 * @param {(string | null)[]} o.headerDataIndexes
 *
 * Get defaultDataIndexes. The priorityMatchField is prioritized.
 */
export const getDefaultDataIndexes = ({ headerDataIndexes, importFields }) => {
  const priorityMatchField = getPriorityMatchField({
    headerDataIndexes,
    importFields,
  });

  return priorityMatchField
    ? [priorityMatchField.dataIndex]
    : importFields
        .filter((field) => {
          return (
            isMatchableField(field) &&
            field.canMatchEntityBy === "is_default" &&
            headerDataIndexes.includes(field.dataIndex)
          );
        })
        .map(({ dataIndex }) => dataIndex);
};
