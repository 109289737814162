// @ts-check

import { Modal } from "antd";
import React, { useRef, useState } from "react";

import * as domain from "@evolved/domain";
import { getPartnersLabel } from "@evolved/labels";

import getErrorMessage from "../../../utils/get-error-message";
import { ErrorMessage } from "../../../components/error-message";
import { useOrganization } from "../../../data/use-organization";

import { ConfigureEntityMatch } from "./configure-matches/configure-entity-match";
import { ConfigureRelationshipMatches } from "./configure-matches/configure-relationship-matches";
import { ConfigureUdfs } from "./configure-udfs";
import { Confirm } from "./confirm";
import { FileUpload } from "./file-upload";
import { GetTemplate } from "./get-template";
import { getImportFields } from "./get-import-fields";
import { useEntityCache } from "./use-entity-cache";
import {
  CREATE_USER_DEFINED_FIELD_OPTION,
  IdentifyHeaders,
} from "./identify-headers";
import { buildImportState } from "./import-state/import-state";

/** @typedef {import("./domain").ImportableEntityTypes} Types */

/**
 * @param {Object} o
 * @param {domain.Organization} o.organization
 * @param {domain.EntityType} o.entityType
 *
 * @returns {string}
 */
const getTitle = ({ organization, entityType }) => {
  /** @type {Record<Types, string>} */
  const options = {
    ACCOUNT: "Account Import",
    CONTACT: "Contact Import",
    OPPORTUNITY: "Opportunity Import",
    VENDOR: `${getPartnersLabel(organization, "singular")} Import`,
  };

  return options[entityType];
};

/** @typedef {import("./domain").ImportField} ImportField
/** @typedef {import("./domain").ImportState} ImportState

/**
 * @typedef {Object} WizardProps
 *
 * @prop {Types} entityType
 * @prop {import("./use-entity-cache").EntityCache} entityCache
 * @prop {domain.Organization} organization
 * @prop {ImportField[]} importFields
 *
 * @prop {import("@evolved/domain").Organization} organization
 * @prop {() => void} onSuccess
 */

/** @type {ImportState} */
const initialTestingState = {
  headerDataIndexes: [
    "name",
    null,
    "contacts",
    null,
    null,
    null,
    null,
    CREATE_USER_DEFINED_FIELD_OPTION.value,
  ],
  newUserDefinedFields: [
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    {
      dataType: "TEXT",
      id: "testing_udf",
      name: "Testing UDF",
      type: "ACCOUNT",
    },
  ],
  entityMatchConfig: {
    dataIndexes: ["name"],
    createIfNoMatch: true,
  },
  relationshipMatchConfigs: {
    "contacts": {
      dataIndexes: ["email"],
      createIfNoMatch: false,
    }
  },
  upload: {
    headers: [
      "Name",
      "Status",
      "Contacts",
      "Last Acted On",
      "Next Followup",
      "Website",
      "Phone",
      "Address",
    ],
    rows: [
      // [
      //   "Foo",
      //   "Client",
      //   "joe@gmail.com, someguy@gmail.com, Brad Abel, John Mang, brad.j.abel@gmail.com",
      //   "Jan 28 2024",
      //   "Jan 22 2020",
      //   "",
      //   "Something",
      //   "",
      // ],
      // [
      //   "Mang",
      //   "Prospect",
      //   "John Mang, foo mah goo",
      //   "Sep 10 2023",
      //   "",
      //   "",
      //   "",
      //   "",
      // ],
      ["Test", "Lead", "brad.j.abel@gmail.com,someguy@gmail.com", "", "", "", "", ""],
      // ["New", "Lead", "", "", "", "", "", "new udf value"],
      // ["Foo Bar", "Lead", "Stan Peake", "", "", "", "", ""],
      // ["foo   ", "Lead", "Stan Peake", "", "", "", "", ""],
    ],
  },
};

/**
 * Import wizard component that guides users through the data import process
 * @param {WizardProps} props
 */
const Wizard = (props) => {
  const { entityType, entityCache, importFields, organization, onSuccess } = props;

  /** @type {domain.UseState<{value?: string}>} */
  const [error, setError] = useState({});

  // TODO: wrap this entire thing in a boundary
  // and display errors under form if any are caught.
  //
  // Have to restart since in a bad state, maybe.
  /**
   * @param {Error} error
   */
  const onError = (error) => {
    console.error(error);
    setError({ value: error.message });
  };

  const importState = useRef(buildImportState({
    entityType,
    entityCache,
    importFields,
    userDefinedFields: organization.userDefinedFields,
  })).current;

  /** @type {{
   *  steps: import("./domain").ReducedImportSteps[];
   *  currentId: import("./domain").StepIds
   * }} */
  const initialState = {
    steps: importState.getSteps(),
    currentId: "upload_file",
  };
  const [state, setState] = useState(initialState);

  /**
   * @param {{
   *  steps?: import("./domain").ReducedImportSteps[];
   *  currentId?: import("./domain").StepIds
   * }} update
   */
  const updateState = (update) => {
    setState((state) => {
      return {
        ...state,
        ...update,
      }
    });

    setError({});
  }

  importState.setOnUpdate((steps) => {
    updateState({ steps });
  });
  importState.setOnError(onError);

  const currentStep =
    state.steps.find((step) => {
      return step.stepId === state.currentId;
    });

  if (!currentStep) {
    throw new Error("could not find valid step.");
  }

  const getCurrentStepIndex = () => {
    const index = state.steps.findIndex((stepState) => {
      return stepState.stepId === currentStep.stepId;
    });

    if (index < 0) {
      throw new Error("Should always find current step.");
    }

    return index;
  }

  const getPreviousStepId = () => {
    const index = getCurrentStepIndex();

    if (index === 0) {
      return state.steps[0].stepId;
    }

    return state.steps[index - 1].stepId;
  };

  /**
   * @returns {import("./domain").StepIds | null}
   */
  const getNextStepId = () => {
    const index = getCurrentStepIndex();

    if (index === state.steps.length - 1) {
      return null;
    }

    return state.steps[index + 1].stepId;
  };

  const onBack = () => {
    updateState({ currentId: getPreviousStepId() });
  };

  const nextId = getNextStepId();

  /**
   * @type {null | ((first?: (() => void)) => void)}
   */
  const onNext = nextId && ((first) => {
    try {
      if (typeof first === "function") {
        first();
      }

      updateState({ currentId: nextId });
    } catch (e) {
      onError(e);
    }
  });

  /** @type {JSX.Element | null} */
  let renderedStep = null;

  if (currentStep.stepId === "upload_file") {
    renderedStep = (
      <div>
        {currentStep.errors?.length ? (
          currentStep.errors.map((error) => {
            if (error.type === "duplicate_headers") {
              return (
                <>
                  <div className="mb-2">Duplicate headers found:</div>
                  <div className="mb-2 font-semibold">
                    {error.duplicateHeaders.join(", ")}
                  </div>
                  <div className="mb-2">
                    Please reconcile them and submit again.
                  </div>
                </>
              );
            }

            return null;
          })
        ) : (
          <GetTemplate {...{ entityType, importFields, onError }} />
        )}
        <FileUpload
          onSuccess={(upload) => {
            importState.setUpload(upload)
            updateState({
              currentId: "configure_header_data_indexes",
            });
          }}
          onNext={onNext}
          onError={onError}
        />
      </div>
    );
  }

  if (currentStep.stepId === "configure_header_data_indexes") {
    renderedStep = (
      <IdentifyHeaders
        {...{
          upload: currentStep.upload,
          headerOptions: currentStep.headerOptions,
          headerDataIndexes: currentStep.headerDataIndexes,
          errors: currentStep.errors,
          setHeaderDataIndex: importState.setHeaderDataIndex,
          onBack,
          onNext: onNext ? () => {
            onNext(() => {
              importState.syncEntityMatchConfigDataIndexes();
            });
          } : null,
        }}
      />
    );
  }

  if (currentStep.stepId === "create_user_defined_fields") {
    renderedStep = (
      <ConfigureUdfs
        {...{
          upload: currentStep.upload,
          newUserDefinedFields: currentStep.newUserDefinedFields,
          setType: importState.setNewUserDefinedFieldType,
          onBack,
          onNext,
        }}
      />
    );
  }

  if (currentStep.stepId === "configure_entity_match") {
    renderedStep = (
      <ConfigureEntityMatch
        {...{
          step: currentStep,
          service: importState,
          onBack,
          onNext,
        }}
      />
    );
  }

  if (currentStep.stepId === "configure_relationships_matches") {
    renderedStep = (
      <ConfigureRelationshipMatches
        {...{
          importFields,
          service: importState,
          step: currentStep,
          onBack,
          onNext,
        }}
      />
    );
  }

  if (currentStep.stepId === "resolve_invalid_fields") {
    renderedStep = (
      <div>TODO: resolve invalid fields</div>
    );
  }

  if (currentStep.stepId === "confirm") {
    renderedStep = (
      <Confirm
        {...{
          entityType,
          existingUdfs: organization.userDefinedFields,
          entities: currentStep.entities,
          newUdfs: currentStep.newUserDefinedFields,
          onBack,
          onSuccess,
        }}
      />
    )
  }

  return (
    <div className="w-full">
      <div className="p-2">
        {renderedStep}
      </div>
      {error.value && (
        <ErrorMessage
          action={null}
          refresh={null}
          error={`${getErrorMessage(
            error.value
          )}. Take any corrective actions and re-upload the file to try again.`}
          style={{ marginTop: "24px" }}
          support={false}
        />
      )}
    </div>
  );
};

/**
 * @param {{
 *  isOpen: boolean;
 *  type: Types;
 *  close: () => void;
 * }} props
 */
export const ImportDataModal = (props) => {
  const { type: entityType, close } = props;

  /** @type {domain.Organization} */
  const organization = useOrganization();

  const entityCache = useEntityCache();
  const importFields = getImportFields({
    entityType,
    userDefinedFields: organization.userDefinedFields,
  });

  return (
    <Modal
      destroyOnClose
      footer={null}
      maskClosable={false}
      onCancel={props.close}
      open={props.isOpen}
      title={getTitle({ entityType, organization })}
    >
      <div className="flex w-[100%] justify-center">
        <Wizard {...{ entityType, entityCache, organization, importFields, onSuccess: close }} />
      </div>
    </Modal>
  );
};
