import { isMatchableField } from "./is-matchable-field";

/**
 * @param {Object} o
 * @param {import("../domain").ImportField[]} o.importFields
 * @param {(string | null)[]} o.headerDataIndexes
 *
 * If priority import field is selected (in headerDataIndexes),
 * return it. Only one is supported today.
 */
export const getPriorityMatchField = ({ headerDataIndexes, importFields }) => {
  return importFields.find((field) => {
    return (
      isMatchableField(field) &&
      field.canMatchEntityBy === "is_priority" &&
      headerDataIndexes.includes(field.dataIndex)
    );
  });
};
