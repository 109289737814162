// @ts-check

import { getEntityMatchKeys } from "./get-entity-matches";
import {
  getRelationshipMatchKeys,
  splitMultiMatchKeys,
} from "./get-relationship-match-keys";
import { zipRow } from "./zip-row";

/**
 * @param {Object} o
 * @param {string[]} o.row
 * @param {(string | null)[]} o.headerDataIndexes
 * @param {string[][]} o.dataIndexes
 */
export const getRowMatchKeys = ({ row, headerDataIndexes, dataIndexes }) => {
  const zipped = zipRow({
    headerDataIndexes,
    row,
  });

  return getEntityMatchKeys({ dataIndexes, entity: zipped });
};

/**
 * @param {Object} o
 * @param {string[][]} o.dataIndexes
 * @param {(string | null)[]} o.headerDataIndexes
 * @param {string[][]} o.rows
 */
export const reduceRowsByMatchKeys = ({
  dataIndexes,
  headerDataIndexes,
  rows,
}) => {
  /**
   * @param {Record<string, number[]>} acc
   * @param {string[]} row
   * @param {number} index
   */
  const reducer = (acc, row, index) => {
    const keys = getRowMatchKeys({ dataIndexes, headerDataIndexes, row });

    for (const key of keys) {
      if ((acc[key] ?? []).includes(index)) {
        continue;
      }

      acc[key] = [...(acc[key] ?? []), index];
    }

    return acc;
  };

  return rows.reduce(reducer, {});
};

/**
 * @param {Object} o
 * @param {string[]} o.row
 * @param {(string | null)[]} o.headerDataIndexes
 * @param {string} o.dataIndex
 * @param {string} [o.split]
 */
export const getRowMultiMatchKeys = ({
  row,
  headerDataIndexes,
  dataIndex,
  split = ",",
}) => {
  const zipped = zipRow({
    headerDataIndexes,
    row,
  });

  return getRelationshipMatchKeys({ dataIndex, entity: zipped, split });
};

/**
 * @param {Object} o
 * @param {"one" | "many"} o.cardinality
 * @param {string} o.dataIndex
 * @param {(string | null)[]} o.headerDataIndexes
 * @param {string[][]} o.rows
 *
 * Determine the multi-match keys for each row. Then
 * for each key, associate that index.
 */
export const reduceRowsByMultiMatchKeys = ({
  cardinality,
  dataIndex,
  headerDataIndexes,
  rows,
}) => {
  /**
   * @param {Record<string, number[]>} acc
   * @param {string[]} row
   * @param {number} index
   */
  const reducer = (acc, row, index) => {
    const keys = getRowMultiMatchKeys({ dataIndex, headerDataIndexes, row });

    if (cardinality === "one" && keys.length > 1) {
      return acc;
    }

    for (const key of keys) {
      acc[key] = [...(acc[key] ?? []), index];
    }

    return acc;
  };

  return rows.reduce(reducer, {});
};

/**
 * @param {Object} o
 * @param {string[][]} o.rows
 * @param {number} o.index
 */
export const getInvalidCardinalityRows = ({ index, rows }) => {
  /** @type {number[]} */
  const invalidIndexes = [];

  for (const [_index, row] of Object.entries(rows)) {
    const value = row[index];
    if (!value) {
      continue;
    }

    const keys = splitMultiMatchKeys({
      value,
    });

    if (keys.length > 1) {
      invalidIndexes.push(Number(_index));
    }
  }

  return invalidIndexes;
};
