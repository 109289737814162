// @ts-check

/**
 * @param {Object} o
 * @param {import("../domain").ImportField[]} o.importFields
 * @param {(string | null)[]} [o.headerDataIndexes]
 * @param {{dataIndexes: string[][]}} o.matchConfig
 */
export const syncMatchConfigDataIndexes = (o) => {
  const { headerDataIndexes } = o;

  const next = o.matchConfig.dataIndexes
    .map((dataIndexes) => {
      if (!headerDataIndexes) {
        return dataIndexes;
      }

      return dataIndexes.filter((dataIndex) => {
        return headerDataIndexes.includes(dataIndex);
      });
    })
    .filter((dataIndexes) => {
      return dataIndexes.length;
    });

  if (!next.length) {
    /** @type {string[]} */
    let defaults = [];

    for (const field of o.importFields) {
      if (headerDataIndexes && !headerDataIndexes.includes(field.dataIndex)) {
        continue;
      }

      if (field.type !== "TEXT" && field.type !== "LINK") {
        continue;
      }

      if (field.canMatchEntityBy === "is_priority") {
        // NOTE: if no headerDataIndexes selections, then
        // we don't assume CRM_ID
        if (headerDataIndexes) {
          defaults = [field.dataIndex];
          break;
        }
      }

      if (field.canMatchEntityBy === "is_default") {
        defaults = [...defaults, field.dataIndex];
      }
    }

    return [defaults];
  }

  return next;
};

/**
 * @param {Object} o
 * @param {(string | null)[]} o.headerDataIndexes
 * @param {import("../domain").EntityMatchConfig} o.entityMatchConfig
 */
export const isEntityMatchConfigUnsynced = (o) => {
  return o.entityMatchConfig.dataIndexes.some((dataIndexes) => {
    return dataIndexes.some((dataIndex) => {
      return !o.headerDataIndexes.includes(dataIndex);
    });
  });
};
