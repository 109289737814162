// @ts-check

import React from "react";
import { Button } from "antd";

import { useImportAccountData } from "../../../data/use-accounts";
import { useImportContactData } from "../../../data/use-contacts";
import { useImportOpportunityData } from "../../../data/use-opportunities";
import { useImportVendorData } from "../../../data/use-vendors";

/** @typedef {import("@evolved/domain").UserDefinedField[]} UDFs */

/**
 * @param {Object} o
 * @param {UDFs} o.existingUdfs
 * @param {UDFs} o.newUdfs
 */
const prepareUdfPayload = ({ existingUdfs, newUdfs }) => {
  const prepared = [...existingUdfs];

  for (const udf of newUdfs) {
    if (prepared.find(({ id }) => udf.id === id)) {
      continue;
    }

    prepared.push(udf);
  }

  return prepared;
};

/**
 * @param {Object} props 
 * @param {import("./domain").ImportableEntityTypes} props.entityType
 * @param {object[]} props.entities
 * @param {UDFs} props.existingUdfs
 * @param {UDFs} props.newUdfs
 * @param {() => void} props.onBack
 * @param {() => void} props.onSuccess
 */
export const Confirm = ({
  entityType,
  newUdfs,
  existingUdfs,
  entities,
  onBack,
  onSuccess,
}) => {
  const importData = ({
    ACCOUNT: useImportAccountData({
      onSuccess,
    }),
    CONTACT: useImportContactData({
      onSuccess,
    }),
    OPPORTUNITY: useImportOpportunityData({
      onSuccess,
    }),
    VENDOR: useImportVendorData({
      onSuccess,
    }),
  })[entityType];

  const onConfirm = () => {
    importData.mutate(
      // @ts-expect-error
      {
        entities,
        userDefinedFields: prepareUdfPayload({
          newUdfs,
          existingUdfs,
        }),
      });
  }

  const updateCount = entities.filter(({ _id }) => !!_id).length;
  const newCount = entities.length - updateCount;

  return (
    <div>
      You are about to update {updateCount} and create {newCount} CRM records. Would you like to proceed?

      <div className="flex justify-end">
        <Button
          className="mr-2"
          disabled={importData.isLoading}
          onClick={onBack}
        >Back</Button>
        <Button
          type="primary"
          onClick={onConfirm}
          loading={importData.isLoading}
          disabled={importData.isLoading}
        >
          Confirm Import
        </Button>
      </div>
    </div>
  )
};
