// @ts-check

import produce from "immer";

import { CREATE_USER_DEFINED_FIELD_OPTION } from "../identify-headers.jsx";
import { buildMapToIndexes } from "./map-to-indexes";
import { getPreviousHeaderIndexes } from "./get-previous-header-indexes";
import { isStateInitialized } from "../domain";
import { reduceColumnOptions } from "../reduce-column-options";
import { reduceSteps } from "./reduce-steps.js";
import { getDefaultDataIndexes } from "../configure-matches/get-default-data-indexes.js";
import { syncMatchConfigDataIndexes } from "./sync-entity-match-config-data-indexes.js";

/** @typedef {import("../domain").ImportState} State */

/**
 * @param {Object} o
 * @param {import("../domain").ImportState} [o.state]
 * @param {import("../domain").ImportableEntityTypes} o.entityType
 * @param {import("../use-entity-cache").EntityCache} o.entityCache
 * @param {import("../domain").ImportField[]} o.importFields
 * @param {import("@evolved/domain").UserDefinedField[]} o.userDefinedFields
 */
export const buildImportState = ({ state: initialState = {}, ...params }) => {
  /**
   * @type {State}
   */
  let state = initialState;
  let steps = reduceSteps({
    state,
    ...params,
  });

  /**
   * @type {(steps: import("../domain").ReducedImportSteps[]) => void}
   */
  let onUpdate;

  /**
   * @param {typeof onUpdate} _onUpdate
   */
  const setOnUpdate = (_onUpdate) => {
    onUpdate = _onUpdate;
  };

  /**
   * @type {(error: Error) => void}
   */
  let onError;

  /**
   * @param {typeof onError} _onError
   */
  const setOnError = (_onError) => {
    onError = _onError;
  };

  /**
   * @param {(state: State) => State} update
   */
  const updateState = (update) => {
    try {
      state = update(state);

      steps = reduceSteps({
        ...params,
        state,
      });

      onUpdate?.(steps);
    } catch (e) {
      if (onError) {
        onError(e);
      }
    }
  };

  /**
   * @param {import("../domain").CSVUpload} upload
   */
  const setUpload = (upload) => {
    updateState(
      produce((draft) => {
        if (!isStateInitialized(draft)) {
          return {
            upload,
            headerDataIndexes: Array(upload.headers.length).fill(null, 0),
            newUserDefinedFields: Array(upload.headers.length).fill(null, 0),
            entityMatchConfig: { createIfNoMatch: true, dataIndexes: [[]] },
            relationshipMatchConfigs: {},
          };
        }

        const mapToIndexes = buildMapToIndexes(
          getPreviousHeaderIndexes({
            next: upload.headers,
            previous: draft.upload.headers,
          })
        );

        const headerDataIndexes = mapToIndexes(draft.headerDataIndexes);
        const newUserDefinedFields = mapToIndexes(draft.newUserDefinedFields);

        const entityMatchConfig = {
          createIfNoMatch: true,
          dataIndexes: syncMatchConfigDataIndexes({
            importFields: params.importFields,
            headerDataIndexes,
            matchConfig: draft.entityMatchConfig,
          }),
        };

        // NOTE: relationshipMatchConfig should be
        // able to stay. Since key of dataIndex could
        // be re-used if they select it again and
        // the dataIndexes on the related entity aren't
        // changing either.
        const relationshipMatchConfigs = draft.relationshipMatchConfigs;

        /** @type {State} */
        const result = {
          upload,
          headerDataIndexes,
          newUserDefinedFields,
          entityMatchConfig,
          relationshipMatchConfigs,
        };

        return result;
      })
    );
  };

  /**
   * @param {Object} o
   * @param {string | null} o.value
   * @param {number} o.index
   */
  const setHeaderDataIndex = ({ value, index }) => {
    updateState(
      produce((draft) => {
        if (!isStateInitialized(draft)) {
          throw new Error("upload must be initialized.");
        }

        draft.headerDataIndexes[index] = value;

        if (value === CREATE_USER_DEFINED_FIELD_OPTION.value) {
          let next = draft.newUserDefinedFields[index];

          if (!next) {
            next = {
              id: crypto.randomUUID(),
              dataType: "TEXT",
              type: params.entityType,
              name: draft.upload.headers[index],
            };
          }

          if (next.dataType === "SELECT") {
            next = {
              ...next,
              options: reduceColumnOptions({
                generateId: () => crypto.randomUUID(),
                index,
                rows: draft.upload.rows,
              }),
            };
          }

          draft.newUserDefinedFields[index] = next;
        }
      })
    );
  };

  /**
   * @param {Object} o
   * @param {import("@evolved/domain").UserDefinedFieldType} o.type
   * @param {number} o.index
   */
  const setNewUserDefinedFieldType = ({ type, index }) => {
    updateState(
      produce((draft) => {
        if (!isStateInitialized(draft)) {
          throw new Error("upload must be initialized.");
        }

        if (type === "CALCULATED") {
          throw new Error(
            "CALCULATED UDF data type is not supported by import."
          );
        }

        let next = draft.newUserDefinedFields[index];

        if (!next) {
          throw new Error(
            "new user defined field config should be set during identify headers step."
          );
        }

        if (type === "SELECT") {
          next = {
            ...next,
            dataType: type,
            options: reduceColumnOptions({
              generateId: () => crypto.randomUUID(),
              index,
              rows: draft.upload.rows,
            }),
          };
        } else {
          next = {
            ...next,
            dataType: type,
          };
        }

        draft.newUserDefinedFields[index] = next;
      })
    );
  };

  /**
   * Remove entityMatchConfig.dataIndexes that are not
   * in current headerDataIndexes (selections) by the user.
   */
  const syncEntityMatchConfigDataIndexes = () => {
    updateState(
      produce((draft) => {
        if (!isStateInitialized(draft)) {
          throw new Error("upload must be initialized.");
        }

        draft.entityMatchConfig.dataIndexes = syncMatchConfigDataIndexes({
          importFields: params.importFields,
          headerDataIndexes: draft.headerDataIndexes,
          matchConfig: draft.entityMatchConfig,
        });

        if (!draft.entityMatchConfig.dataIndexes.length) {
          draft.entityMatchConfig.dataIndexes = [
            getDefaultDataIndexes({
              headerDataIndexes: draft.headerDataIndexes,
              importFields: params.importFields,
            }),
          ];
        }
      })
    );
  };

  const canAddEntityMatchKey = (_state = state) => {
    if (!isStateInitialized(_state)) {
      return false;
    }

    return _state.entityMatchConfig.dataIndexes.length < 5;
  };

  const canRemoveEntityMatchKey = (_state = state) => {
    if (!isStateInitialized(_state)) {
      return false;
    }

    return _state.entityMatchConfig.dataIndexes.length > 1;
  };

  const addEntityMatchKey = () => {
    updateState(
      produce((draft) => {
        if (!isStateInitialized(draft)) {
          throw new Error("upload must be initialized.");
        }

        if (!canAddEntityMatchKey(draft)) {
          return;
        }

        draft.entityMatchConfig.dataIndexes.push([]);
      })
    );
  };

  /**
   * @param {Object} o
   * @param {number} o.index
   */
  const removeEntityMatchKey = ({ index }) => {
    updateState(
      produce((draft) => {
        if (!isStateInitialized(draft)) {
          throw new Error("upload must be initialized.");
        }

        if (!canRemoveEntityMatchKey(draft)) {
          return;
        }

        draft.entityMatchConfig.dataIndexes.splice(index, 1);
      })
    );
  };

  /**
   * @param {Object} o
   * @param {string[]} o.value
   * @param {number} o.index
   */
  const setEntityMatchKey = ({ value, index }) => {
    updateState(
      produce((draft) => {
        if (!isStateInitialized(draft)) {
          throw new Error("upload must be initialized.");
        }

        if (!draft.entityMatchConfig.dataIndexes[index]) {
          throw new Error(
            `attempted to set entity match key at unexisting index ${index}.`
          );
        }

        draft.entityMatchConfig.dataIndexes[index] = value;
      })
    );
  };

  /**
   * @param {Object} o
   * @param {boolean} o.value
   */
  const setCreateEntityIfNoMatch = ({ value }) => {
    updateState(
      produce((draft) => {
        if (!isStateInitialized(draft)) {
          throw new Error("upload must be initialized.");
        }

        draft.entityMatchConfig.createIfNoMatch = value;
      })
    );
  };

  const canAddRelationshipMatchKey = (_state = state) => {
    /**
     * @param {string} dataIndex
     */
    const hof = (dataIndex) => {
      if (!isStateInitialized(_state)) {
        return false;
      }

      return (
        (_state.relationshipMatchConfigs[dataIndex]?.dataIndexes ?? []).length <
        5
      );
    };

    return hof;
  };

  const canRemoveRelationshipMatchKey = (_state = state) => {
    /**
     * @param {string} dataIndex
     */
    const hof = (dataIndex) => {
      if (!isStateInitialized(_state)) {
        return false;
      }

      return (
        (_state.relationshipMatchConfigs[dataIndex]?.dataIndexes ?? []).length >
        1
      );
    };

    return hof;
  };

  /**
   * @param {string} dataIndex
   */
  const addRelationshipMatchKey = (dataIndex) => {
    updateState(
      produce((draft) => {
        if (!isStateInitialized(draft)) {
          throw new Error("upload must be initialized.");
        }

        if (!canAddRelationshipMatchKey(draft)(dataIndex)) {
          return;
        }

        if (!draft.relationshipMatchConfigs[dataIndex]) {
          draft.relationshipMatchConfigs[dataIndex] = {
            dataIndexes: [[]],
          };
        }

        draft.relationshipMatchConfigs[dataIndex].dataIndexes.push([]);
      })
    );
  };

  /**
   * @param {Object} o
   * @param {string} o.dataIndex
   * @param {number} o.index
   */
  const removeRelationshipMatchKey = ({ dataIndex, index }) => {
    updateState(
      produce((draft) => {
        if (!isStateInitialized(draft)) {
          throw new Error("upload must be initialized.");
        }

        if (!canRemoveRelationshipMatchKey(draft)(dataIndex)) {
          return;
        }

        draft.relationshipMatchConfigs[dataIndex].dataIndexes.splice(index, 1);
      })
    );
  };
  /**
   * @param {Object} o
   * @param {string[]} o.value
   * @param {string} o.dataIndex
   * @param {number} o.index
   */
  const setRelationshipMatchKey = ({ value, dataIndex, index }) => {
    updateState(
      produce((draft) => {
        if (!isStateInitialized(draft)) {
          throw new Error("upload must be initialized.");
        }

        if (!draft.relationshipMatchConfigs[dataIndex]) {
          draft.relationshipMatchConfigs[dataIndex] = { dataIndexes: [[]] };
        }

        const current =
          draft.relationshipMatchConfigs[dataIndex].dataIndexes[index];

        if (!current) {
          throw new Error(
            `attempted to set relationship match key at unexisting data index ${dataIndex} and index ${index}.`
          );
        }

        draft.relationshipMatchConfigs[dataIndex].dataIndexes[index] = value;
      })
    );
  };

  const getSteps = () => {
    return steps;
  };

  return {
    getSteps,
    setOnError,
    setOnUpdate,

    addEntityMatchKey,
    addRelationshipMatchKey,
    canAddEntityMatchKey,
    canAddRelationshipMatchKey,
    canRemoveEntityMatchKey,
    canRemoveRelationshipMatchKey,
    removeEntityMatchKey,
    removeRelationshipMatchKey,
    setCreateEntityIfNoMatch,
    setEntityMatchKey,
    setHeaderDataIndex,
    setNewUserDefinedFieldType,
    setRelationshipMatchKey,
    setUpload,
    syncEntityMatchConfigDataIndexes,
  };
};

/** @typedef {ReturnType<typeof buildImportState>} ImportStateService */
