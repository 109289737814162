// @ts-check

/** @typedef {import("@evolved/domain").SystemField} SystemField */

import { reduceFieldsToMap } from "./reduce-fields-to-map.js";

/** @type {SystemField} */
export const address = {
  entityType: "ACCOUNT",
  dataIndex: "address",
  title: "Address",
  type: "TEXT",
  canEdit: true,
  isSystem: true,
};

/** @type {SystemField} */
export const contacts = {
  entityType: "ACCOUNT",
  dataIndex: "contacts",
  title: "Contacts",
  type: "SET",
  collection: "contacts",
  relationship: {
    entityType: "CONTACT",
    cardinality: "many",
  },
  canEdit: true,
  isSystem: true,
};

/** @type {SystemField} */
export const createdOn = {
  entityType: "ACCOUNT",
  dataIndex: "createdOn",
  title: "Added On",
  type: "DATE",
  canEdit: false,
  isSystem: true,
};

/** @type {SystemField} */
export const lastActedOn = {
  entityType: "ACCOUNT",
  dataIndex: "lastActedOn",
  title: "Last Acted On",
  type: "ACTIVITY_DATE",
  canEdit: false,
  isSystem: true,
};

/** @type {SystemField} */
export const name = {
  entityType: "ACCOUNT",
  dataIndex: "name",
  title: "Name",
  type: "TEXT",
  mapUiUrl: (value) => `/accounts/${value}`,
  canEdit: true,
  canMatchEntityBy: "is_default",
  isRequired: true,
  isSystem: true,
};

/** @type {SystemField} */
export const nextFollowup = {
  entityType: "ACCOUNT",
  dataIndex: "nextFollowupOn",
  title: "Next Followup",
  type: "FOLLOWUP_DATE",
  canEdit: false,
  isSystem: true,
};

/** @type {SystemField} */
export const phone = {
  entityType: "ACCOUNT",
  dataIndex: "phone",
  title: "Phone",
  type: "TEXT",
  canEdit: true,
  isSystem: true,
};

/** @type {SystemField} */
export const state = {
  entityType: "ACCOUNT",
  dataIndex: "state",
  title: "Status",
  type: "ACCOUNT_STATE",
  canEdit: false,
  isSystem: true,
};

/** @type {SystemField} */
export const tags = {
  entityType: "ACCOUNT",
  dataIndex: "tags",
  title: "Tags",
  type: "SET",
  collection: "tags",
  relationship: {
    entityType: "TAG",
    cardinality: "many",
  },
  canEdit: true,
  isSystem: true,
};

/** @type {SystemField} */
export const users = {
  entityType: "ACCOUNT",
  dataIndex: "users",
  title: "Sales Reps",
  type: "SET",
  collection: "users",
  relationship: {
    entityType: "USER",
    cardinality: "many",
  },
  canEdit: true,
  isSystem: true,
};

/** @type {SystemField} */
export const website = {
  entityType: "ACCOUNT",
  dataIndex: "website",
  title: "Website",
  type: "LINK",
  canEdit: true,
  canMatchEntityBy: true,
  isSystem: true,
};

export const COLLECTION = Object.freeze([
  name,
  createdOn,
  nextFollowup,
  lastActedOn,
  address,
  phone,
  contacts,
  state,
  tags,
  users,
  website,
]);

export const DEFAULT_FIELDS = Object.freeze([
  "state",
  "contacts",
  "lastActedOn",
  "nextFollowupOn",
  "website",
  "phone",
  "address",
]);

export const FIXED_FIELDS = Object.freeze(["name"]);

export const MAP = Object.freeze(reduceFieldsToMap(COLLECTION));
